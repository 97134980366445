// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Theme$SimonKjellberg from "../Theme.bs.js";

var styles = Curry._1(Css.style, {
      hd: Css.position(Css.relative),
      tl: {
        hd: Css.marginTop(Css.em(1.0)),
        tl: {
          hd: Css.marginBottom(Css.em(1.0)),
          tl: {
            hd: Css.marginLeft(Css.ch(1.5)),
            tl: {
              hd: Css.fontSize(Css.rem(1.0)),
              tl: {
                hd: Css.fontWeight("bold"),
                tl: {
                  hd: Css.before({
                        hd: Css.position(Css.absolute),
                        tl: {
                          hd: Css.left(Css.ch(-1.5)),
                          tl: {
                            hd: Css.contentRule({
                                  NAME: "text",
                                  VAL: ">"
                                }),
                            tl: {
                              hd: Css.color(Theme$SimonKjellberg.Color.muted),
                              tl: /* [] */0
                            }
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function Heading(Props) {
  var level = Props.level;
  var children = Props.children;
  if (level === "Level2") {
    return React.createElement("h2", {
                className: styles
              }, children);
  } else if (level === "Level3") {
    return React.createElement("h3", {
                className: styles
              }, children);
  } else if (level === "Level4") {
    return React.createElement("h4", {
                className: styles
              }, children);
  } else if (level === "Level5") {
    return React.createElement("h5", {
                className: styles
              }, children);
  } else if (level === "Level6") {
    return React.createElement("h6", {
                className: styles
              }, children);
  } else {
    return React.createElement("h1", {
                className: styles
              }, children);
  }
}

var make = Heading;

var $$default = Heading;

var level1 = "Level1";

var level2 = "Level2";

var level3 = "Level3";

var level4 = "Level4";

var level5 = "Level5";

var level6 = "Level6";

export {
  styles ,
  make ,
  $$default ,
  $$default as default,
  level1 ,
  level2 ,
  level3 ,
  level4 ,
  level5 ,
  level6 ,
  
}
/* styles Not a pure module */
