// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Theme$SimonKjellberg from "../Theme.bs.js";
import * as Utils$SimonKjellberg from "../Utils.bs.js";
import * as UnorderedList$SimonKjellberg from "./UnorderedList.bs.js";

var styles = Curry._1(Css.style, {
      hd: Css.position(Css.relative),
      tl: {
        hd: Css.before({
              hd: Css.contentRule({
                    NAME: "text",
                    VAL: "*"
                  }),
              tl: {
                hd: Css.position(Css.absolute),
                tl: {
                  hd: Css.left(Css.ch(-1.5)),
                  tl: {
                    hd: Css.color(Theme$SimonKjellberg.Color.muted),
                    tl: /* [] */0
                  }
                }
              }
            }),
        tl: {
          hd: Css.selector("& > ." + UnorderedList$SimonKjellberg.styles, {
                hd: Css.marginTop(Css.zero),
                tl: {
                  hd: Css.marginBottom(Css.zero),
                  tl: /* [] */0
                }
              }),
          tl: /* [] */0
        }
      }
    });

var make = Utils$SimonKjellberg.forwardDOMRef(function (Props, ref) {
      var className = Props.className;
      var style = Props.style;
      var children = Props.children;
      var tmp = {
        className: Utils$SimonKjellberg.cn({
              hd: styles,
              tl: {
                hd: Utils$SimonKjellberg.$pipe$question(className, ""),
                tl: /* [] */0
              }
            })
      };
      if (ref !== undefined) {
        tmp.ref = Caml_option.valFromOption(ref);
      }
      if (style !== undefined) {
        tmp.style = Caml_option.valFromOption(style);
      }
      return React.createElement("li", tmp, children);
    });

var $$default = make;

export {
  styles ,
  make ,
  $$default ,
  $$default as default,
  
}
/* styles Not a pure module */
