// Generated by ReScript, PLEASE EDIT WITH CARE

import Config from "next/config";

var Link = {};

var Head = {};

var $$Error = {};

function getConfig(prim) {
  return Config();
}

var Config$1 = {
  getConfig: getConfig
};

export {
  Link ,
  Head ,
  $$Error ,
  Config$1 as Config,
  
}
/* next/config Not a pure module */
